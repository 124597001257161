<div class="w-full py-8 px-4 sm:p-12 md:p-16 md:pt-24">
    <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
        <!-- Logo -->
        <div class="w-full">
            <img src="assets/images/logo/logo-text.png" class="object-scale-down h-48 w-full" alt="logivice_logo" />
        </div>

        <!-- Title -->
        <div class="mt-8 text-2xl font-extrabold tracking-tight leading-tight">Password recovery</div>

        <div *ngIf="linkSent" class="py-3">
            <p class="text-green-500">Your reset password email is heading your way.</p>
            <p>Use link in email to reset your password.</p>
            <div class="mt-8">
                Return to
                <a class="font-medium text-primary-500 hover:underline" [routerLink]="['/auth/login']">sign in</a>
            </div>
        </div>

        <form class="mt-8" [formGroup]="forgotPasswordForm" *ngIf="!linkSent">
            <!-- Email field -->
            <mat-form-field class="w-full">
                <mat-label>Email address</mat-label>
                <input id="email" matInput formControlName="email" />
                <mat-error *ngIf="forgotPasswordForm.get('email').hasError('required')">Email address is required</mat-error>
                <mat-error *ngIf="forgotPasswordForm.get('email').hasError('email')">Please enter a valid email address</mat-error>
            </mat-form-field>

            <button class="fuse-mat-button-large w-full mt-6" mat-flat-button [color]="'primary'" [disabled]="forgotPasswordForm.disabled" (click)="sendResetLink()">
                <span *ngIf="!forgotPasswordForm.disabled">Send reset link</span>
                <mat-progress-spinner *ngIf="forgotPasswordForm.disabled" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
            </button>
            <div class="mt-8">
                Return to
                <a class="font-medium text-primary-500 hover:underline" [routerLink]="['/auth/login']">sign in</a>
            </div>
        </form>
    </div>
</div>
