import { Route } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { Login2faComponent } from './login-2fa/login-2fa.component';
import { AcceptInvitationComponent } from './accept-invitation/accept-invitation.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './forgot-password/reset-password/reset-password.component';

// @formatter:off
/* eslint-disable max-len */
export const authRoutes: Route[] = [
    {
        path: 'login',
        title: 'Login',
        component: LoginComponent
    },
    {
        path: '2fa',
        title: 'Login 2fa',
        component: Login2faComponent
    },
    {
        path: 'accept-invitation',
        title: 'Invitation accept',
        component: AcceptInvitationComponent
    },
    {
        path: 'forgot-password',
        title: 'Password recovery',
        component: ForgotPasswordComponent
    },
    {
        path: 'reset-password/:token',
        title: 'Password reset',
        component: ResetPasswordComponent
    }
];
