<div class="w-full py-8 px-4 sm:p-12 md:p-16 md:pt-24">
    <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
        <!-- Logo -->
        <div class="w-12">
            <img src="assets/images/logo/logo.svg" alt="logivice_logo" />
        </div>

        <!-- Title -->
        <div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight">Sign up</div>

        <!-- Sign in form -->
        <form class="mt-8 space-y-4" [formGroup]="acceptInvitationForm" #signInNgForm="ngForm">
            <!-- Email field -->
            <mat-form-field class="w-full">
                <mat-label>Email address</mat-label>
                <input id="email" matInput [formControlName]="'email'" />
                <mat-error *ngIf="acceptInvitationForm.get('email').hasError('required')">Email address is required</mat-error>
                <mat-error *ngIf="acceptInvitationForm.get('email').hasError('email')">Please enter a valid email address</mat-error>
            </mat-form-field>

            <!-- Email field -->
            <mat-form-field class="w-full">
                <mat-label>Full name</mat-label>
                <input id="name" matInput [formControlName]="'name'" />
                <mat-error *ngIf="acceptInvitationForm.get('name').hasError('required')">Full name address is required</mat-error>
            </mat-form-field>

            <!-- Password field -->
            <mat-form-field class="w-full">
                <mat-label>Password</mat-label>
                <input id="password" matInput type="password" [formControlName]="'password'" #passwordField />
                <button mat-icon-button type="button" (click)="passwordField.type === 'password' ? (passwordField.type = 'text') : (passwordField.type = 'password')" matSuffix>
                    <mat-icon class="icon-size-5" *ngIf="passwordField.type === 'password'" [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                    <mat-icon class="icon-size-5" *ngIf="passwordField.type === 'text'" [svgIcon]="'heroicons_solid:eye-off'"></mat-icon>
                </button>
                <mat-error>Password is required</mat-error>
            </mat-form-field>

            <!-- Password field -->
            <mat-form-field class="w-full">
                <mat-label>Password confirmation</mat-label>
                <input id="confirmPassword" matInput type="password" [formControlName]="'confirmPassword'" #passwordField [errorStateMatcher]="matcher" />
                <mat-error *ngIf="acceptInvitationForm.hasError('notSame')">Passwords do not match</mat-error>
            </mat-form-field>

            <!-- Submit button -->
            <button class="fuse-mat-button-large w-full mt-6" mat-flat-button [color]="'primary'" [disabled]="acceptInvitationForm.invalid" (click)="signIn()">
                <span *ngIf="!processing">Submit</span>
                <mat-progress-spinner *ngIf="processing" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
            </button>
        </form>
    </div>
</div>
