<div class="w-full py-8 px-4 sm:p-12 md:p-16 md:pt-24">
    <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
        <!-- Logo -->
        <div class="w-full">
            <img src="assets/images/logo/logo-text.png" class="object-scale-down h-48 w-full" alt="logivice_logo" />
        </div>
        <!-- Title -->
        <div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight">Sign in</div>

        <!-- Alert -->
        <fuse-alert class="mt-8 -mb-4" *ngIf="showAlert" [appearance]="'outline'" [showIcon]="false" [type]="alert.type" [@shake]="alert.type === 'error'">
            {{ alert.message }}
        </fuse-alert>

        <!-- Sign in form -->
        <form class="mt-8 space-y-4" [formGroup]="signInForm" #signInNgForm="ngForm">
            <!-- Email field -->
            <mat-form-field class="w-full">
                <mat-label>Email address</mat-label>
                <input id="email" matInput [formControlName]="'email'" />
                <mat-error *ngIf="signInForm.get('email').hasError('required')">Email address is required</mat-error>
                <mat-error *ngIf="signInForm.get('email').hasError('email')">Please enter a valid email address</mat-error>
            </mat-form-field>

            <!-- Password field -->
            <mat-form-field class="w-full">
                <mat-label>Password</mat-label>
                <input id="password" matInput type="password" [formControlName]="'password'" #passwordField />
                <button mat-icon-button type="button" (click)="passwordField.type === 'password' ? (passwordField.type = 'text') : (passwordField.type = 'password')" matSuffix>
                    <mat-icon class="icon-size-5" *ngIf="passwordField.type === 'password'" [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                    <mat-icon class="icon-size-5" *ngIf="passwordField.type === 'text'" [svgIcon]="'heroicons_solid:eye-off'"></mat-icon>
                </button>
                <mat-error>Password is required</mat-error>
            </mat-form-field>

            <!-- Actions -->
            <div class="inline-flex items-end justify-between w-full mt-1.5">
                <mat-checkbox [color]="'primary'" [formControlName]="'remember'">Remember me</mat-checkbox>
                <a class="text-md font-medium text-primary-500 hover:underline" [routerLink]="['/auth/forgot-password']">Forgotten password?</a>
            </div>

            <!-- Submit button -->
            <button class="fuse-mat-button-large w-full mt-6" mat-flat-button [color]="'primary'" [disabled]="signInForm.disabled" (click)="signIn()">
                <span *ngIf="!signInForm.disabled">Sign in</span>
                <mat-progress-spinner *ngIf="signInForm.disabled" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
            </button>
        </form>

        <div class="flex gap-4 justify-center mt-6">
            @for (type of providersByType() | keys; track type.key) {
                <!-- direct -->
                @if (type.value.length === 1) {
                    <button mat-stroked-button (click)="loginVia(type.value[0])">
                        <mat-icon [svgIcon]="'logivice:' + type.key" class="icon-size-4"></mat-icon>
                        <span class="ml-2">{{ type.key | ucfirst }}</span>
                    </button>

                    <!-- select by organisation -->
                } @else {
                    <button mat-stroked-button [matMenuTriggerFor]="moreMenu">
                        <mat-icon [svgIcon]="'logivice:' + type.key" class="icon-size-4"></mat-icon>
                        <span class="ml-2">{{ type.key | ucfirst }}</span>
                    </button>
                    <mat-menu #moreMenu="matMenu">
                        @for (provider of type.value; track provider.provider) {
                            <button mat-menu-item (click)="loginVia(provider)">
                                <span>{{ provider.organisation_name }} {{ type.key }}</span>
                            </button>
                        }
                    </mat-menu>
                }
            }
        </div>

        @if (error) {
            <div class="text-warn-500 text-center p-5 rounded border border-warn-500 mt-5">{{ error }}</div>
        }
    </div>
</div>
