<div class="w-full py-8 px-4 sm:p-12 md:p-16 md:pt-24">
    <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
        <!-- Logo -->
        <div class="w-12">
            <img src="assets/images/logo/logo.svg" alt="logivice_logo" />
        </div>

        <!-- Title -->
        <div class="mt-8 text-4xl font-extrabold tracking-tight leading-tight">Verify You identity through 2FA</div>

        <!-- Alert -->
        <fuse-alert class="mt-8 -mb-4 space-y-4" *ngIf="showAlert" [appearance]="'outline'" [showIcon]="false" [type]="alert.type" [@shake]="alert.type === 'error'">
            {{ alert.message }}
        </fuse-alert>

        <!-- Sign in form -->
        <form class="mt-8" [formGroup]="twoFactorForm" #signInNgForm="ngForm">
            <!-- Code field -->
            <mat-form-field class="w-full">
                <mat-label>Code</mat-label>
                <input id="code" matInput [formControlName]="'code'" />
                <mat-error *ngIf="twoFactorForm.get('code').hasError('required')">Code is required</mat-error>
            </mat-form-field>

            <!-- Actions -->
            <div class="inline-flex items-end justify-between w-full mt-1.5">
                <mat-checkbox [(ngModel)]="useRecovery" [ngModelOptions]="{ standalone: true }" [color]="'primary'">Use Recovery</mat-checkbox>
            </div>

            <!-- Submit button -->
            <button class="fuse-mat-button-large w-full mt-6" mat-flat-button [color]="'primary'" [disabled]="twoFactorForm.disabled" (click)="submit()">
                <span *ngIf="!twoFactorForm.disabled">Verify</span>
                <mat-progress-spinner *ngIf="twoFactorForm.disabled" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
            </button>
        </form>
    </div>
</div>
