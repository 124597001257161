<div class="w-full py-8 px-4 sm:p-12 md:p-16 md:pt-24">
    <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
        <!-- Logo -->
        <div class="w-full">
            <img src="assets/images/logo/logo-text.png" class="object-scale-down h-48 w-full" alt="logivice_logo" />
        </div>

        <!-- Title -->
        <div class="mt-8 text-2xl font-extrabold tracking-tight leading-tight">New password</div>

        <form class="mt-8 space-y-4" [formGroup]="resetPasswordForm">
            <!-- Password field -->
            <mat-form-field class="w-full">
                <mat-label>Password</mat-label>
                <input id="password" matInput type="password" [formControlName]="'password'" #passwordField />
                <button mat-icon-button type="button" (click)="passwordField.type === 'password' ? (passwordField.type = 'text') : (passwordField.type = 'password')" matSuffix>
                    <mat-icon class="icon-size-5" *ngIf="passwordField.type === 'password'" [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                    <mat-icon class="icon-size-5" *ngIf="passwordField.type === 'text'" [svgIcon]="'heroicons_solid:eye-off'"></mat-icon>
                </button>
                <mat-error *ngIf="resetPasswordForm.get('password').hasError('required')">Password is required</mat-error>
            </mat-form-field>

            <!-- Password field -->
            <mat-form-field class="w-full">
                <mat-label>Repeat password</mat-label>
                <input id="password_confirmation" matInput type="password" [formControlName]="'password_confirmation'" #passwordConfirmationField />
                <button
                    mat-icon-button
                    type="button"
                    (click)="passwordConfirmationField.type === 'password' ? (passwordConfirmationField.type = 'text') : (passwordConfirmationField.type = 'password')"
                    matSuffix
                >
                    <mat-icon class="icon-size-5" *ngIf="passwordConfirmationField.type === 'password'" [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                    <mat-icon class="icon-size-5" *ngIf="passwordConfirmationField.type === 'text'" [svgIcon]="'heroicons_solid:eye-off'"></mat-icon>
                </button>
                <mat-error *ngIf="resetPasswordForm.get('password_confirmation').hasError('required')">Password confirmation is required</mat-error>
                <mat-error *ngIf="resetPasswordForm.get('password_confirmation').hasError('mustMatch')">Passwords must match</mat-error>
            </mat-form-field>

            <button class="fuse-mat-button-large w-full mt-6" mat-flat-button [color]="'primary'" [disabled]="resetPasswordForm.disabled" (click)="resetPassword()">
                <span *ngIf="!resetPasswordForm.disabled">Save</span>
                <mat-progress-spinner *ngIf="resetPasswordForm.disabled" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
            </button>
            <div class="mt-8">
                Return to
                <a class="font-medium text-primary-500 hover:underline" [routerLink]="['/auth/login']">sign in</a>
            </div>
        </form>
    </div>
</div>
