import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthDispatchers } from '@store/auth/auth.dispatchers';
import { fuseAnimations } from '@animations/public-api';
import { FuseAlertType } from '../../../../@fuse/components/alert';

@Component({
    selector: 'app-auth-login-2fa',
    templateUrl: './login-2fa.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class Login2faComponent implements OnInit {
    twoFactorForm: FormGroup;
    useRecovery = false;

    alert: { type: FuseAlertType; message: string } = {
        type: 'success',
        message: ''
    };
    showAlert: boolean = false;

    constructor(
        private _formBuilder: FormBuilder,
        private authDispatchers: AuthDispatchers
    ) {}

    ngOnInit(): void {
        this.twoFactorForm = this._formBuilder.group({
            code: ['', [Validators.required]]
        });
    }

    submit(): void {
        const data = {
            [(this.useRecovery ? 'recovery_' : '') + 'code']: this.twoFactorForm.value.code
        };

        this.authDispatchers.twoFactorChallenge(data);
    }
}
