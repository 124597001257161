import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { Login2faComponent } from './login-2fa/login-2fa.component';
import { FuseAlertModule } from '../../../@fuse/components/alert';
import { authRoutes } from './auth.routing';
import { AcceptInvitationComponent } from './accept-invitation/accept-invitation.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './forgot-password/reset-password/reset-password.component';

@NgModule({
    imports: [RouterModule.forChild(authRoutes), SharedModule, RouterModule, ReactiveFormsModule, FuseAlertModule],
    providers: [],
    declarations: [
        LoginComponent,
        Login2faComponent,
        AcceptInvitationComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent
    ]
})
export class AuthModule {}
